<template>
  <div>
  </div>
  </template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'querySonstiges',
  watch: {
    sonstiges() {
      this.$store.commit('setSonstiges', this.sonstiges);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_GRAPHQL,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const context = {
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
      },
    };

    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang),
    });

    const querySonstiges = gql`
        query ($lang:I18NLocaleCode!) {
          sonstige(locale: $lang){
            data {
              attributes {
                oeffnungszeiten
              }
            }
          }
        }
      `;
    const { data: sonstiges } = useQuery({
      query: querySonstiges,
      variables,
      context,
    });
    return { sonstiges };
  },
};
</script>
