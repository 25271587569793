<script>

export default {
  name: 'changeLang',
  computed: {
    currentLang() {
      return this.$store.state.lang.currentLang;
    },
  },

  watch: {
    $route() {
      if (this.$route.params.lang) {
        if (this.$route.params.lang !== this.currentLang.toLowerCase()) this.$store.commit('setLang', this.$route.params.lang);
      }
    },
  },

  methods: {
    changeLanguage(lang) {
      document.documentElement.setAttribute('lang', lang?.toLowerCase());
      if (lang !== this.currentLang && this.$route.params.lang) {
        const newParams = { ...this.$route.params };
        const current = this.$route;
        this.$store.commit('setLang', lang);
        newParams.lang = this.currentLang.toLowerCase();
        this.$router.replace({ name: current.name, params: newParams });
      }
    },
  },
};
</script>
