/* eslint-disable import/prefer-default-export */
export const footer = {
  state: {
    de: {
      contact: 'Kontakt/Anfahrt',
    },
    en: {
      contact: 'Contact/Directions',
    },
  },
};
