import { createRouter, createWebHistory } from 'vue-router';
// eslint-disable-next-line import/extensions
import store from '../store/index.js';

const Home = () => import('../views/home.vue');
const Pages = () => import('../views/pages.vue');
const Kontakt = () => import('../views/kontakt.vue');
const Utility = () => import('../views/utility.vue');
const News = () => import('../views/news.vue');
const Book = () => import('../views/book.vue');

const routes = [
  {
    path: '/:lang/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/:lang/:slug',
    name: 'Pages',
    component: Pages,
  },
  {
    path: '/:lang/kontakt',
    name: 'Kontakt',
    component: Kontakt,
  },
  {
    path: '/:lang/book/:slug',
    name: 'Book',
    component: Book,
  },
  {
    path: '/:lang/news',
    name: 'News',
    component: News,
  },
  {
    path: '/:lang/utility/:slug',
    name: 'Utility',
    component: Utility,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next({ replace: false, name: 'Home', params: { lang: 'de' } });
  } else if (to.params.lang !== 'de' && to.params.lang !== 'en') {
    next({ replace: false, name: 'Home', params: { lang: 'de' } });
  } else {
    next();
  }
});

router.afterEach((to) => {
  store.commit('setMobileMenue', false);
  if (to.name === 'Pages' || to.name === 'Utility') {
    store.commit('setSlug', to.params.slug);
  }
});

export default router;
