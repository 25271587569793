/* eslint-disable import/prefer-default-export */
export const contact = {
  state: {
    de: {
      message: 'Nachricht',
      submit: 'Nachricht abschicken',
      submitted: 'Danke für deine Nachricht, wir werden uns in Kürze bei dir melden.',
      select: 'Anfragegrund auswählen',
      common: 'Allgemeine Anfrage',
      course: 'Kursanfrage',
    },
    en: {
      message: 'Message',
      submit: 'Submit message',
      submitted: 'Thank you for contacting us. We will get back to you quickly.',
      select: 'Select reason for contact',
      common: 'General contact',
      course: 'Course request',
    },
  },
};
