/* eslint-disable import/extensions */
/* eslint-disable import/prefer-default-export */
import { home } from './home.js';
import { footer } from './footer.js';
import { contact } from './contact.js';
import { news } from './news.js';

export const lang = {
  modules: {
    home,
    footer,
    contact,
    news,
  },
  state: {
    currentLang: 'de',
    fallbackLang: 'en',
  },
  mutations: {

    setLang(state, language) {
      if (language === 'de') {
        state.currentLang = language;
        state.fallbackLang = 'en';
        window.localStorage.setItem('onyxLang', 'de');
      } else {
        state.currentLang = language;
        state.fallbackLang = 'de';
        window.localStorage.setItem('onyxLang', 'en');
      }
    },

  },
  actions: {
  },
};
