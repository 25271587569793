/* eslint-disable import/prefer-default-export */
export const home = {
  state: {
    de: {
      news: 'Aktuelles',
      allNews: 'Alle Neuigkeiten anzeigen',
      book: 'Kurs buchen',
      prices: 'Preise',
      next: 'Weiter zu',
      reduced: 'Ermäßigt',
      contact: 'Kontakt',
    },
    en: {
      news: 'News',
      allNews: 'Show all news',
      book: 'Book course',
      prices: 'Prices',
      next: 'Next',
      reduced: 'Reduced',
      contact: 'Contact',
    },
  },
};
