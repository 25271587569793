<template>
<mobileMenue />
<div class="transition duration-500 ease-in-out fixed top-0 w-full z-50 font-rouben flex flex-row justify-center pb-9" :class="{'bg-new-btransparent backdrop-filter backdrop-blur-lg' : showMenuBg}">
  <div v-if="pages" class="w-full h-auto flex flex-row justify-between items-end text-new-white pt-7 px-8 md:px-20 max-w-screen-2xl">
    <router-link :to="{ name: 'Home', params: { lang: currentLang } }">
      <svg class="transition duration-500 ease-in-out w-48 md:w-32 h-auto text-new-white fill-current  md:hover:opacity-50 -mb-px" viewBox="0 0 591 115" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
          <g transform="matrix(1,0,0,1,-320.635,-538.017)">
              <g transform="matrix(1,0,0,1,-88.084,206.028)">
                  <g transform="matrix(1,0,0,1,-1.60379,0)">
                      <path d="M872.084,446.756L920.91,446.756L926.346,437.806L931.783,446.756L980.609,446.756L953.519,404.89L1000.57,331.988L950.868,331.988L926.346,371.974L901.825,331.988L852.12,331.988L899.174,404.89L872.084,446.756Z" style="fill-rule:nonzero;"/>
                  </g>
                  <g transform="matrix(1,0,0,1,-5.52282,0)">
                      <path d="M754.255,446.756L798.88,446.756L798.88,420.353L852.341,331.988L803.519,331.988L776.347,380.368L749.174,331.988L700.352,331.988L754.255,420.795L754.255,446.756Z" style="fill-rule:nonzero;"/>
                  </g>
                  <path d="M557.864,446.756L602.709,446.756L602.709,402.239L627.75,446.756L689.528,446.756L689.528,331.988L644.903,331.988L644.903,410.192L601.163,331.988L557.864,331.988L557.864,446.756Z" style="fill-rule:nonzero;"/>
                  <g transform="matrix(1,0,0,1,13.2263,2.43004)">
                      <path d="M395.493,444.326L395.493,376.834C395.493,344.58 413.166,329.558 445.64,329.558L483.858,329.558C516.332,329.558 534.005,344.58 534.005,376.834L534.005,444.326L489.381,444.326L489.381,378.822C489.381,370.427 485.183,366.672 476.568,366.672L452.93,366.672C444.535,366.672 440.117,370.427 440.117,378.822L440.117,444.326L395.493,444.326Z" style="fill-rule:nonzero;"/>
                  </g>
              </g>
          </g>
          </svg>
    </router-link>
    <div class="hidden h-full md:flex flex-row items-end flex-grow px-8">
      <router-link :to="{ name: 'Pages', params: { lang: currentLang, slug: page?.attributes?.slug } }" v-for="page in pages.pages.data" :key="page" class="transition duration-500 ease-in-out -mb-2 px-4 text-xl font-medium cursor-pointer hover:opacity-50">
      {{page.attributes.name}}
      </router-link>
    </div>
    <div class="transition duration-500 ease-in-out order-last md:order-none text-xl -mb-2 font-medium cursor-pointer select-none md:hover:opacity-50" @click="changeLang(fallbackLang)">{{fallbackLang}}</div>
    <div class="order-first flex md:hidden" @click="showMenue()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 -mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M4 8h16M4 16h16" />
      </svg>
    </div>
  </div>
</div>

<queryPages />
</template>

<script>
// GraphQL
import queryPages from '../graphql/queryPages.vue';

// Components
import mobileMenue from './mobileMenue.vue';

// Mixins
import changeLang from '../mixins/changeLang.vue';

export default {
  name: 'Navbar',
  components: { queryPages, mobileMenue },
  mixins: [changeLang],

  data() {
    return {
      showMenuBg: false,
    };
  },

  computed: {
    currentLang() {
      return this.$store.state.lang.currentLang;
    },
    pages() {
      return this.$store.state.data.pages;
    },
    fallbackLang() {
      return this.$store.state.lang.fallbackLang;
    },
  },

  mounted() {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset === 0) {
        this.showMenuBg = false;
      } else {
        this.showMenuBg = true;
      }
    });
  },

  methods: {

    showMenue() {
      this.$store.commit('setMobileMenue', true);
    },

    changeLang(lang) {
      this.changeLanguage(lang);
    },
  },
};
</script>
