<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryPages',
  watch: {
    pages() {
      this.$store.commit('setPages', this.pages);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_GRAPHQL,
      cachePolicy: 'network-only',
    });

    const store = useStore();

    const context = {
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
      },
    };

    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang),
    });

    const queryPages = gql`
      query($lang: I18NLocaleCode!) {
        pages(sort: "Reihenfolge:asc", locale: $lang) {
          data {
            attributes {
              name
              slug
            }
          }
        }
      }
    `;
    const { data: pages } = useQuery({
      query: queryPages,
      variables,
      context,
    });
    return { pages };
  },
};
</script>
