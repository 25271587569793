// eslint-disable-next-line import/prefer-default-export
export const data = {
  state: {
    mobile: false,
    currentSlug: '',
    relatedSlug: '',
    pages: null,
    currentPage: null,
    currentPageImages: null,
    relatedImages: null,
    home: null,
    contact: null,
    homeImage: null,
    newsPreview: null,
    allNews: null,
    utilityPages: null,
    sonstiges: null,
    currentUtilityPage: null,
    showMobileMenue: false,
  },
  mutations: {
    setMobile(state, value) {
      state.mobile = value;
    },

    setSonstiges(state, value) {
      state.sonstiges = value;
    },

    setPages(state, pages) {
      state.pages = pages;
    },

    setHome(state, home) {
      state.home = home;
    },

    setContact(state, contact) {
      state.contact = contact;
    },

    setHomeImage(state, homeImage) {
      state.homeImage = homeImage;
    },

    setCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
      if (typeof currentPage !== 'undefined') {
        state.relatedSlug = currentPage?.pages?.data[0]?.attributes?.page?.data?.attributes?.slug;
      }
    },

    setCurrentUtilityPage(state, currentUtilityPage) {
      state.currentUtilityPage = currentUtilityPage;
    },

    setAllNews(state, allNews) {
      state.allNews = allNews;
    },

    setCurrentPageImages(state, currentPageImages) {
      state.currentPageImages = currentPageImages;
    },

    setRelatedImages(state, relatedImages) {
      state.relatedImages = relatedImages;
    },

    setNewsPreview(state, newsPreview) {
      state.newsPreview = newsPreview;
    },

    setUtilityPages(state, utilityPages) {
      state.utilityPages = utilityPages;
    },

    setMobileMenue(state, value) {
      state.showMobileMenue = value;
    },

    setSlug(state, slug) {
      state.currentSlug = slug;
    },

  },
  actions: {
  },
  modules: {
  },
};
