<template>
<transition @enter="fadeIn" @leave="fadeOut" mode="out-in" :css="false">
  <div :key=showMobileMenue class="flex flex-col justify-center items-center fixed top-0 w-full h-screen z-100 font-rouben text-new-white bg-new-black" :class="{'hidden' : !showMobileMenue}">
    <router-link :to="{ name: 'Home', params: { lang: currentLang } }">
      <svg class="w-48 h-auto text-new-white fill-current pt-7" viewBox="0 0 591 115" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
          <g transform="matrix(1,0,0,1,-320.635,-538.017)">
              <g transform="matrix(1,0,0,1,-88.084,206.028)">
                  <g transform="matrix(1,0,0,1,-1.60379,0)">
                      <path d="M872.084,446.756L920.91,446.756L926.346,437.806L931.783,446.756L980.609,446.756L953.519,404.89L1000.57,331.988L950.868,331.988L926.346,371.974L901.825,331.988L852.12,331.988L899.174,404.89L872.084,446.756Z" style="fill-rule:nonzero;"/>
                  </g>
                  <g transform="matrix(1,0,0,1,-5.52282,0)">
                      <path d="M754.255,446.756L798.88,446.756L798.88,420.353L852.341,331.988L803.519,331.988L776.347,380.368L749.174,331.988L700.352,331.988L754.255,420.795L754.255,446.756Z" style="fill-rule:nonzero;"/>
                  </g>
                  <path d="M557.864,446.756L602.709,446.756L602.709,402.239L627.75,446.756L689.528,446.756L689.528,331.988L644.903,331.988L644.903,410.192L601.163,331.988L557.864,331.988L557.864,446.756Z" style="fill-rule:nonzero;"/>
                  <g transform="matrix(1,0,0,1,13.2263,2.43004)">
                      <path d="M395.493,444.326L395.493,376.834C395.493,344.58 413.166,329.558 445.64,329.558L483.858,329.558C516.332,329.558 534.005,344.58 534.005,376.834L534.005,444.326L489.381,444.326L489.381,378.822C489.381,370.427 485.183,366.672 476.568,366.672L452.93,366.672C444.535,366.672 440.117,370.427 440.117,378.822L440.117,444.326L395.493,444.326Z" style="fill-rule:nonzero;"/>
                  </g>
              </g>
          </g>
          </svg>
    </router-link>
    <div v-if="pages" class="w-full h-full flex flex-col justify-center items-center">
      <div class="flex flex-col justify-center items-center -mt-24">
        <router-link :to="{ name: 'Pages', params: { lang: currentLang, slug: page.attributes.slug } }" v-for="page in pages.pages.data" :key="page" class="text-4xl mt-5 font-medium ">
        {{page.attributes.name}}
        </router-link>
      </div>
      <div class="mt-8 w-10 h-10 rounded-full border-2 border-new-white flex flex-row justify-center items-center" @click="closeMenue()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
// eslint-disable-next-line import/extensions
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'mobileMenue',

  computed: {
    currentLang() {
      return this.$store.state.lang.currentLang;
    },
    showMobileMenue() {
      return this.$store.state.data.showMobileMenue;
    },
    pages() {
      return this.$store.state.data.pages;
    },
  },

  watch: {
    showMobileMenue() {
      if (this.showMobileMenue) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'visible';
      }
    },
  },

  methods: {
    closeMenue() {
      this.$store.commit('setMobileMenue', false);
    },

    fadeIn(el, done) {
      anime({
        targets: el,
        easing: 'easeInSine',
        duration: 350,
        translateX: ['100vw', '0vw'],
        complete: done,
      });
    },

    fadeOut(el, done) {
      anime({
        targets: el,
        easing: 'easeInSine',
        duration: 350,
        opacity: [1, 0],
        complete: done,
      });
    },

  },
};
</script>
