<script>
export default {
  name: 'hrefLinks',
  computed: {
    currentLang() {
      return this.$store.state.lang.currentLang;
    },
    fallbackLang() {
      return this.$store.state.lang.fallbackLang;
    },
  },

  watch: {
    $route() {
      this.setHrefLinks();
    },
    currentLang() {
      this.setHrefLinks();
    },
  },

  methods: {
    setHrefLinks() {
      const fullPath = `https://onyx-leipzig.de${this.$route.fullPath}`;
      const de = '/de/';
      const en = '/en/';
      const dePath = fullPath.replace(de, en);
      const enPath = fullPath.replace(en, de);
      document.querySelector('link[hreflang="de"]').setAttribute('href', enPath);
      document.querySelector('link[hreflang="en"]').setAttribute('href', dePath);
    },
  },
};
</script>
