/* eslint-disable import/prefer-default-export */
export const news = {
  state: {
    de: {
      news: 'Aktuelles',
    },
    en: {
      news: 'News',
    },
  },
};
