/* eslint-disable import/extensions */
import { createStore } from 'vuex';
import { data } from './data/index.js';
import { lang } from './lang/index.js';

export default createStore({
  modules: {
    data,
    lang,
  },
});
