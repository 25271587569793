<template>
  <querySonstiges />
  <navbar />
  <div id="pageBlackFast" class="fixed w-full h-screen top-0 bg-new-light z-50"></div>
  <div id="pageBlack" class="fixed w-full h-screen top-0 bg-new-black z-100 font-rouben flex flex-row justify-center items-center md:items-end text-new-light">
    <svg class="w-auto h-auto text-new-light fill-current" viewBox="0 0 591 115" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
          <g transform="matrix(1,0,0,1,-320.635,-538.017)">
              <g transform="matrix(1,0,0,1,-88.084,206.028)">
                  <g transform="matrix(1,0,0,1,-1.60379,0)">
                      <path d="M872.084,446.756L920.91,446.756L926.346,437.806L931.783,446.756L980.609,446.756L953.519,404.89L1000.57,331.988L950.868,331.988L926.346,371.974L901.825,331.988L852.12,331.988L899.174,404.89L872.084,446.756Z" style="fill-rule:nonzero;"/>
                  </g>
                  <g transform="matrix(1,0,0,1,-5.52282,0)">
                      <path d="M754.255,446.756L798.88,446.756L798.88,420.353L852.341,331.988L803.519,331.988L776.347,380.368L749.174,331.988L700.352,331.988L754.255,420.795L754.255,446.756Z" style="fill-rule:nonzero;"/>
                  </g>
                  <path d="M557.864,446.756L602.709,446.756L602.709,402.239L627.75,446.756L689.528,446.756L689.528,331.988L644.903,331.988L644.903,410.192L601.163,331.988L557.864,331.988L557.864,446.756Z" style="fill-rule:nonzero;"/>
                  <g transform="matrix(1,0,0,1,13.2263,2.43004)">
                      <path d="M395.493,444.326L395.493,376.834C395.493,344.58 413.166,329.558 445.64,329.558L483.858,329.558C516.332,329.558 534.005,344.58 534.005,376.834L534.005,444.326L489.381,444.326L489.381,378.822C489.381,370.427 485.183,366.672 476.568,366.672L452.93,366.672C444.535,366.672 440.117,370.427 440.117,378.822L440.117,444.326L395.493,444.326Z" style="fill-rule:nonzero;"/>
                  </g>
              </g>
          </g>
          </svg>
  </div>
  <div id="smooth-wrapper">
    <div id="smooth-content">
      <!--- ALL YOUR CONTENT HERE --->
      <router-view v-slot="{ Component }" class="w-full h-auto">
        <transition @enter="pageSlideIn" @leave="pageSlideOut" :css="false" mode="out-in">
          <component :is="Component" :key="$route.fullPath"/>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';

/* The following plugin is a Club GSAP perk */
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// eslint-disable-next-line import/extensions
import anime from 'animejs/lib/anime.es.js';

// GraphQL
import querySonstiges from './graphql/querySonstiges.vue';

// Components
import navbar from './components/navbar.vue';

// Mixins
import hrefLinks from './mixins/hrefLinks.vue';
import changeLang from './mixins/changeLang.vue';

export default {
  name: 'App',
  components: { querySonstiges, navbar },
  mixins: [hrefLinks, changeLang],

  beforeMount() {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
  },

  mounted() {
    ScrollSmoother.create({
      smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
      effects: true, // looks for data-speed and data-lag attributes on elements
    });

    // Device detection
    if (window.innerWidth <= 768 || navigator.maxTouchPoints > 2) {
      this.$store.commit('setMobile', true);
    }
    // User language detection
    /* if (window.localStorage.onyxLang) {
      this.$store.commit('setLang', window.localStorage.onyxLang);
    } */
  },

  updated() {
    console.log('updated');
    ScrollTrigger.refresh();
  },

  methods: {
    pageSlideIn(el, done) {
      const dark = document.getElementById('pageBlack');
      const fast = document.getElementById('pageBlackFast');
      anime({
        targets: [dark, fast],
        easing: 'easeInOutCubic',
        duration: 750,
        translateX: ['0vw', '-100vw'],
        complete: done,
      });
    },

    pageSlideOut(el, done) {
      const dark = document.getElementById('pageBlack');
      const fast = document.getElementById('pageBlackFast');

      const timeline = anime.timeline({
        easing: 'easeInOutCubic',
        duration: 750,
      });

      timeline
        .add({
          targets: fast,
          translateX: ['100vw', '0vw'],
        })
        .add({
          targets: dark,
          translateX: ['100vw', '0vw'],
          complete: done,
        }, 220);
    },
  },
};
</script>

<style>
html {background: #09151B;}
</style>
